import { ChainId } from './types';

export const PROVIDER_CONFIGS: Partial<Record<ChainId, string[]>> = {
    [ ChainId.ETHEREUM_MAINNET ]: [
        "https://rpc.ankr.com/eth",
        "https://cloudflare-eth.com",
        "https://eth.public-rpc.com",
        "https://rpc.flashbots.net",
        "https://eth-rpc.gateway.pokt.network"
    ],
    [ ChainId.ETHEREUM_GOERLI ]: [
        "https://eth-goerli.g.alchemy.com/v2/mYiO4yTkY6GqVl96FkM5uJnnEb68NhCz",
        "https://eth-goerli.g.alchemy.com/v2/45B406IBlDc3EIK0-pJZuD3bB3nIZFwK"
    ],
    [ ChainId.ETHEREUM_SEPOLIA ]: [
        "https://eth-sepolia.public.blastapi.io",
        // "https://eth-sepolia-public.unifra.io",
        "https://endpoints.omniatech.io/v1/eth/sepolia/public",
        "https://sepolia.gateway.tenderly.co"
    ],
    [ ChainId.ETHEREUM_ROPSTEN ]: [
        "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
        "https://rpc.ankr.com/eth_ropsten"
    ],
    [ ChainId.BSC_MAINNET ]: [
        'https://bsc-dataseed.binance.org/',
        'https://bsc-dataseed1.defibit.io/',
        'https://bsc-dataseed1.ninicoin.io/',
        'https://bsc-dataseed1.binance.org/',
        'https://bsc-dataseed2.binance.org/',
        'https://bsc-dataseed3.binance.org/'
    ],
    [ ChainId.BSC_TESTNET ]: [
        // 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        // 'https://data-seed-prebsc-2-s1.binance.org:8545/',
        // 'https://data-seed-prebsc-1-s2.binance.org:8545/',
        // 'https://data-seed-prebsc-1-s3.binance.org:8545/',
        // 'https://data-seed-prebsc-2-s3.binance.org:8545/'
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://bsc-testnet.publicnode.com",
        "https://bsc-testnet.public.blastapi.io"
    ],
    [ ChainId.POLYGON_MAINNET ]: [
        "https://rpc-mainnet.maticvigil.com",
        "https://polygon-mainnet.public.blastapi.io",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://rpc.ankr.com/polygon",
        "https://polygon-rpc.com",
        "https://matic-mainnet-archive-rpc.bwarelabs.com"
    ],
    [ ChainId.POLYGON_AMOY ]: [
        // 'https://rpc-amoy.polygon.technology',
        'https://polygon-amoy.drpc.org',
        'https://polygon-amoy-bor-rpc.publicnode.com',
        'https://polygon-amoy.blockpi.network/v1/rpc/public',
        "https://polygon-amoy.g.alchemy.com/v2/uhdNWjVnw-5ClHctRr2SQJQNjLpLhj5x"
    ],
    [ ChainId.ARBITRUM_MAINNET ]: [
        // 'https://arb1.croswap.com/rpc',
        'https://arb-mainnet.g.alchemy.com/v2/lhcNo4T7Tt9NR5gbu9peLQs3RSKf1P1q',
        // 'https://arb-mainnet-public.unifra.io',
        // 'https://arb1.arbitrum.io/rpc',
        // 'https://arb1.arbitrum.io/rpc',
        'https://rpc.ankr.com/arbitrum',
    ],
    [ ChainId.BASE_MAINNET ]: [
        'https://base.llamarpc.com',
        'https://mainnet.base.org'
    ],
};

export const BLOCKCHAIN_SCANS: Partial<Record<ChainId, string>> = {
    [ ChainId.ETHEREUM_ROPSTEN ]: `https://ropsten.etherscan.io`,
    [ ChainId.ETHEREUM_GOERLI ]: `https://goerli.etherscan.io`,
    [ ChainId.ETHEREUM_SEPOLIA ]: `https://sepolia.etherscan.io`,
    [ ChainId.ETHEREUM_KOVAN ]: `https://kovan.etherscan.io`,
    [ ChainId.ETHEREUM_MAINNET ]: `https://etherscan.io`,
    [ ChainId.BSC_TESTNET ]: `https://testnet.bscscan.com`,
    [ ChainId.BSC_MAINNET ]: `https://bscscan.com`,
    [ ChainId.POLYGON_MAINNET ]: `https://polygonscan.com`,
    [ ChainId.POLYGON_AMOY ]: `https://amoy.polygonscan.com`,
    [ ChainId.AVALANCHE_MAINNET ]: `https://snowtrace.io`,
    [ ChainId.AVALANCHE_TESTNET ]: `https://testnet.snowtrace.io`,
    [ ChainId.ARBITRUM_MAINNET ]: `https://arbiscan.io`,
    [ ChainId.BASE_MAINNET ]: 'https://basescan.org',
    [ ChainId.TON_MAINNET ]: 'https://tonviewer.com',
    [ ChainId.TON_TESTNET ]: 'https://testnet.tonviewer.com'
};
